import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72')
];

export const server_loads = [0,5,6,7];

export const dictionary = {
		"/(main)": [~25,[5]],
		"/(main)/about": [26,[5]],
		"/(admin)/admin": [~8,[2]],
		"/(admin)/admin/emails": [~9,[2]],
		"/(admin)/admin/emails/[key]": [~10,[2]],
		"/(main)/artists": [~27,[5]],
		"/(main)/artists/[slug]": [~28,[5]],
		"/(main)/artworks": [29,[5]],
		"/(main)/artworks/[slug]": [~30,[5,6]],
		"/(main)/artworks/[slug]/favourite-piece": [~31,[5,6]],
		"/(main)/artworks/[slug]/poster": [~32,[5,6]],
		"/(main)/artworks/[slug]/purchase": [~33,[5,6]],
		"/(auth)/authenticate": [14,[3]],
		"/auth/auth-code-error": [67],
		"/auth/confirm": [~68],
		"/(main)/benefits": [34,[5]],
		"/(main)/book-tour": [~35,[5]],
		"/(main)/cart-item": [37,[5]],
		"/(main)/cart-item/[cartItemId]/coowner-preview": [~38,[5]],
		"/(main)/cart": [36,[5]],
		"/(check-email)/check-email/forgot": [23,[4]],
		"/(check-email)/check-email/signup": [24,[4]],
		"/(main)/checkout": [39,[5]],
		"/(main)/collection": [~40,[5]],
		"/(none)/color": [66],
		"/(auth)/confirmed/[id]": [~15,[3]],
		"/(main)/contact": [41,[5]],
		"/(main)/criipto/callback": [~42,[5]],
		"/(main)/criipto/login": [~43,[5]],
		"/(admin)/customers": [~11,[2]],
		"/(admin)/customers/[id]": [~12,[2]],
		"/(main)/eckersberg": [44,[5]],
		"/(auth)/forgot-password": [16,[3]],
		"/(main)/gifts": [~45,[5]],
		"/(main)/gifts/collect": [~46,[5]],
		"/help": [69],
		"/help/[help_area]": [70,[7]],
		"/help/[help_area]/[category]": [~71,[7]],
		"/help/[help_area]/[category]/[topic]": [~72,[7]],
		"/(main)/how-it-works": [47,[5]],
		"/(main)/legal/sign/[accountId]": [~49,[5]],
		"/(main)/legal/[agreement]": [~48,[5]],
		"/(auth)/login": [17,[3]],
		"/(auth)/logout": [18,[3]],
		"/(main)/newsletter": [~50,[5]],
		"/(main)/page/[slug]": [~51,[5]],
		"/(main)/press": [52,[5]],
		"/(main)/prices": [53,[5]],
		"/(admin)/products/config": [13,[2]],
		"/(main)/profile": [~54,[5]],
		"/(auth)/set-password": [~19,[3]],
		"/(auth)/set-username": [~20,[3]],
		"/(main)/showroom": [55,[5]],
		"/(auth)/signup": [21,[3]],
		"/(auth)/signup/[username]": [~22,[3]],
		"/(main)/stripe/cancel": [56,[5]],
		"/(main)/stripe/success/[stripeCheckoutId]": [~57,[5]],
		"/(main)/subscription": [58,[5]],
		"/(main)/subscription/success": [59,[5]],
		"/(main)/transfers": [~60,[5]],
		"/(main)/transfers/collect/[sqid]": [~61,[5]],
		"/(main)/transfers/complete/[sqid]": [~62,[5]],
		"/(main)/transfers/legal/[agreementName]/[productId]": [~63,[5]],
		"/(main)/transfers/view/[sqid]": [~64,[5]],
		"/(main)/verify-identity": [~65,[5]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';